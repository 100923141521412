import auth from '@/middleware/auth.js'

export default [
	{
		path: ''
		,component: () => import('@/layouts/Constelacion')
		,children: [
			{
				path: '/avaluo_digital'
				,name: 'avaluo_digital'
				,component: () => import('@/apps/avaluodigital/Dashboard')
				,meta: {
					title: 'Avaluo digital'
					,middleware: auth
				}
			}, {
				path: '/avaluo_digital/solicitud/:solicitud',
				name: 'avaluo_digital_solicitud',
				component: () =>
					import ('@/apps/avaluodigital/pages/DetallesValuador.vue'),
				meta: {
					title: 'Solicitud - Avaluo',
					middleware: auth
				}
			},
		]
	}
]